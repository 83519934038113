import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Banner,
  Button,
  PhoneCodeDropdown,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { GAEventType, sendGAEvent } from '../../helpers/GoogleAnalytics';
import useDebounce from '../../hooks/useDebounce';
import { useApi } from '../../providers/ApiProvider';
import { useOrderBooking } from '../../providers/OrderBookingProvider';
import { useOrderInfo } from '../../providers/OrderInfoProvider';
import translationKeys from '../../translations/translationKeys';
import Enterprise from '../../types/Enterprise';
import styles from './ContactInformation.module.scss';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

const ContactInformation = () => {
  const navigate = useNavigate();
  const api = useApi();
  const [searchOrgNumberLoading, setSearchOrgNumberLoading] = useState(false);
  const [createOrderLoading, setCreateOrderLoading] = useState(false);
  const {
    sourceLanguage,
    targetLanguages,
    deadline,
    files,
    totalWordCount,
    handleSetSelectedEnterprise,
  } = useOrderInfo();
  const { getLogoutOrderToken, handleSetOrder, handleSetIsOrderCreated, handleSetCustomerType } =
    useOrderBooking();
  const [selectedPhoneCode, setSelectedPhoneCode] = useState<SearchDropdownMenuOption>();
  const [orgNumberOptions, setOrgNumberOptions] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedOrgNumber, setSelectedOrgNumber] = useState<SearchDropdownMenuOption>();
  const [apiErrors, setApiErrors] = useState<string>();

  const onChangeOrgNumber = (orgNumbers: SearchDropdownMenuOption[] | null) => {
    if (orgNumbers && orgNumbers.length > 0) {
      setSelectedOrgNumber(orgNumbers[0]);
    } else {
      setSelectedOrgNumber(undefined);
    }
  };

  const debounceEnterpriseSearch = useDebounce(300);
  const intl = useIntl();
  const phoneRegex = new RegExp(/\d+/);
  const emailRegex = new RegExp(/^[^\s@]+(\+[^\s@]+)?@[^\s@]+\.[a-zA-Z]{2,}$/);
  const schema = yup.object().shape({
    firstName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.contact_info_first_name_label,
          }),
        },
      ),
    ),
    lastName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.contact_info_last_name_label,
          }),
        },
      ),
    ),
    email: yup
      .string()
      .matches(
        emailRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.contact_info_email_label,
            }),
          },
        ),
      )
      .required(
        intl.formatMessage(
          { id: translationKeys.form_error_required },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.contact_info_email_label,
            }),
          },
        ),
      ),
    phoneNumber: yup
      .string()
      .matches(
        phoneRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.contact_info_phone_label,
            }),
          },
        ),
      )
      .notRequired(),
  });

  const searchOrgNumber = async (query: string) => {
    const { data } = await api.publicSearchEnterprises(
      {
        query,
        search_brreg: true,
      },
      setSearchOrgNumberLoading,
    );

    if (data && (data?.enterprises || data?.brregSearch)) {
      const combinedEnterpriseOptions =
        data.enterprises
          .concat(data.brregSearch)
          .reduce<Enterprise[]>((accumulator, current) => {
            if (!accumulator.find((enterprise) => enterprise.orgNumber === current.orgNumber)) {
              accumulator.push(current);
            }
            return accumulator;
          }, [])
          .map((enterprise) => {
            return {
              id: Number(enterprise.orgNumber),
              label: enterprise.name,
              key: enterprise.id.toString(),
              subtitle: `Org. nr. ${enterprise.orgNumber}`,
            };
          }) || [];

      setOrgNumberOptions(combinedEnterpriseOptions);
    }
  };

  const handleSubmit = async (values: FormValues) => {
    const token = await getLogoutOrderToken();
    const requestBody = new FormData();
    if (token) {
      requestBody.append('order[logout_token]', token);
    }
    if (sourceLanguage) requestBody.append('order[source_language_id]', sourceLanguage.toString());
    if (targetLanguages && targetLanguages.length) {
      targetLanguages.forEach((lang) => {
        requestBody.append('order[target_languages][][id]', lang.toString());
      });
    }
    if (deadline) requestBody.append('order[external_deadline]', deadline.toISOString());

    for (const file of files) {
      if (file.id) {
        requestBody.append('order[attachments][][id]', file.id.toString());
        requestBody.append(
          'order[attachments][][count_of_words]',
          file.wordCount ? file.wordCount.toString() : '0',
        );
      }
    }

    if (selectedOrgNumber) {
      requestBody.append('booker[org_name]', selectedOrgNumber.label);
      requestBody.append('booker[org_number]', selectedOrgNumber.id.toString());
    }

    requestBody.append('order[count_of_words]', totalWordCount.toString());
    requestBody.append('booker[first_name]', values.firstName);
    requestBody.append('booker[last_name]', values.lastName);
    requestBody.append('booker[email]', values.email);
    if (values.phoneNumber && selectedPhoneCode?.key) {
      requestBody.append('booker[phone_code]', selectedPhoneCode.key);
      requestBody.append('booker[phone_number]', values.phoneNumber);
    }

    const { data, error } = await api.createOrder(
      requestBody,
      {
        token,
      },
      setCreateOrderLoading,
    );

    if (data) {
      handleSetCustomerType(data.order?.logoutCustomerType);
      handleSetOrder(data.order);
      handleSetIsOrderCreated(true);
      sendGAEvent(GAEventType.OrderDrafted);
      handleSetSelectedEnterprise(parseFloat(data.order.enterprise?.orgNumber) || null);
      navigateToEstimate();
    }

    if (error) {
      let errorText = intl.formatMessage({
        id: translationKeys.logged_out_booking_default_error_message,
      });

      if (typeof error.text === 'string') {
        errorText = error.text;
      } else if (typeof error.text === 'object' && 'global!' in error.text) {
        const globalErrors = (error.text as { 'global!': string[] })['global!'];
        errorText = globalErrors.length > 0 ? globalErrors[0] : errorText;
      }

      setApiErrors(errorText);
    }
  };

  const navigateToEstimate = () => {
    navigate('/estimate');
  };

  useEffect(() => {
    setSelectedPhoneCode({
      id: 47,
      label: '+47',
      key: '+47',
    });

    sendGAEvent(GAEventType.ContactPageLanded);
  }, []);

  return (
    <div className={styles.content}>
      <div className={styles.getQuoteSection}>
        <h3 className={styles.heading}>
          <FormattedMessage id={translationKeys.get_quote_title} />
        </h3>
        <span className={styles.description}>
          <FormattedMessage id={translationKeys.get_quote_subtitle} />
        </span>
      </div>
      <div className={styles.whatYouNeedSection}>
        <h3 className={styles.heading} data-testid="form-title">
          <FormattedMessage id={translationKeys.contact_info_form_title} />
        </h3>
        <span className={styles.description} data-testid="form-subtitle">
          <FormattedMessage id={translationKeys.contact_info_form_subtitle} />
        </span>
      </div>
      <Formik
        validationSchema={schema}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleChange, handleBlur, values, touched, errors, isValid, dirty }) => (
          <Form onSubmit={handleSubmit} className={styles.formContainer}>
            <div className={styles.nameInputs}>
              <TextField
                data-testid="first-name-input"
                placeholder={intl.formatMessage({
                  id: translationKeys.contact_info_first_name_placeholder,
                })}
                type="text"
                label={intl.formatMessage({
                  id: translationKeys.contact_info_first_name_label,
                })}
                size="large"
                name="firstName"
                required
                autocomplete={false}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                errorText={touched.firstName ? errors.firstName : undefined}
              />

              <TextField
                data-testid="last-name-input"
                placeholder={intl.formatMessage({
                  id: translationKeys.contact_info_last_name_placeholder,
                })}
                type="text"
                required
                autocomplete={false}
                label={intl.formatMessage({
                  id: translationKeys.contact_info_last_name_label,
                })}
                size="large"
                name="lastName"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                errorText={touched.lastName ? errors.lastName : undefined}
              />
            </div>

            <TextField
              data-testid="email-input"
              placeholder={intl.formatMessage({
                id: translationKeys.contact_info_email_placeholder,
              })}
              type="email"
              label={intl.formatMessage({
                id: translationKeys.contact_info_email_label,
              })}
              size="large"
              required
              autocomplete={false}
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              errorText={touched.email ? errors.email : undefined}
            />

            <div className={styles.phoneInputs}>
              <div data-testid="phone-code-dropdown">
                <PhoneCodeDropdown
                  selected={selectedPhoneCode}
                  placeholder={intl.formatMessage({
                    id: translationKeys.contact_info_code_label,
                  })}
                  label={intl.formatMessage({
                    id: translationKeys.contact_info_code_label,
                  })}
                  onChange={(phoneCodes) => {
                    if (phoneCodes && phoneCodes.length > 0 && phoneCodes[0].key) {
                      setSelectedPhoneCode(phoneCodes[0]);
                    } else {
                      setSelectedPhoneCode(undefined);
                    }
                  }}
                />
              </div>

              <TextField
                data-testid="phone-number-input"
                placeholder={intl.formatMessage({
                  id: translationKeys.contact_info_phone_placeholder,
                })}
                type="text"
                label={intl.formatMessage({
                  id: translationKeys.contact_info_phone_label,
                })}
                autocomplete={false}
                size="large"
                name="phoneNumber"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phoneNumber}
                errorText={touched.phoneNumber ? errors.phoneNumber : undefined}
              />
            </div>

            <div className={styles.orgNumber} data-testid="org-number-dropdown">
              <SearchDropdown
                options={orgNumberOptions}
                placeholder={intl.formatMessage({
                  id: translationKeys.invoicing_details_org_number_placeholder,
                })}
                label={intl.formatMessage({
                  id: translationKeys.invoicing_details_org_number_label,
                })}
                size="large"
                required
                isLoading={searchOrgNumberLoading}
                search
                onSearch={(query: string) => {
                  let orgNumber = query;
                  orgNumber = orgNumber.replace(/\s/g, '');
                  if (orgNumber.length == 9 && !isNaN(parseInt(orgNumber))) {
                    return debounceEnterpriseSearch(() => {
                      searchOrgNumber(orgNumber);
                    });
                  }
                  return debounceEnterpriseSearch(() => {
                    searchOrgNumber(query);
                  });
                }}
                onChange={onChangeOrgNumber}
              />
              <span className={styles.hint}>
                <FormattedMessage
                  key={translationKeys.invoicing_details_org_number_search_hint}
                  id={translationKeys.invoicing_details_org_number_search_hint}
                  values={{
                    email: (
                      <a
                        href="mailto: oversettelse@salita.no"
                        onClick={() => sendGAEvent(GAEventType.ContactEmailClicked)}
                      >
                        {'oversettelse@salita.no'}
                      </a>
                    ),
                  }}
                />
              </span>
            </div>

            <div>
              {apiErrors && <Banner variant="error" text={apiErrors} />}
              <Button
                data-testid="submit-button"
                className={styles.nextButton}
                size="large"
                variant="primary"
                fullWidth={true}
                type="submit"
                isLoading={createOrderLoading}
                disabled={!isValid || !dirty || !selectedOrgNumber}
              >
                <FormattedMessage id={translationKeys.contact_info_show_quote_button} />
              </Button>
              <span className={styles.orderNote}>
                <FormattedMessage id={translationKeys.logged_out_booking_prices_note} />
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactInformation;
