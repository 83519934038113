import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  faArrowDownToLine,
  faBuilding,
  faCalendar,
  faTrash,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Banner,
  Button,
  CircularProgressBar,
  DatePicker,
  DetailItem,
  FileSelector,
  FileState,
  InlineError,
  objectToFile,
  RadioOptions,
  SearchDropdown,
  SearchDropdownMenuOption,
  Spinner,
  TextField,
} from '@skiwo/components';
import { formatFileSize } from '@skiwo/utils';
import ActionCable from 'actioncable';
import { addDays, format, isBefore } from 'date-fns';
import { useFormik } from 'formik';
import * as yup from 'yup';
import fileIconIllustration from '../../assets/illustration-file.svg';
import { GAEventType, sendGAEvent } from '../../helpers/GoogleAnalytics';
import { useApi } from '../../providers/ApiProvider';
import { useLanguages } from '../../providers/LanguagesProvider';
import { useOrderBooking } from '../../providers/OrderBookingProvider';
import { useOrderInfo } from '../../providers/OrderInfoProvider';
import translationKeys from '../../translations/translationKeys';
import Language from '../../types/Language';
import { LogoutOrderFile, OCRChannel } from '../../types/LogoutOrderFile';
import styles from './OrderInformation.module.scss';

interface FormValues {
  totalWordCount: string;
}

enum CustomerType {
  Enterprise = 'ENTERPRISE',
  Private = 'PRIVATE',
}

enum FileActionType {
  Rename = 'RENAME',
  Download = 'DOWNLOAD',
  Delete = 'DELETE',
}

function OrderInformation() {
  const navigate = useNavigate();
  const api = useApi();
  const { languages } = useLanguages();
  const [selectedCustomerType, setSelectedCustomerType] = useState<CustomerType>(
    CustomerType.Enterprise,
  );
  const [activeDatePicker, setActiveDatePicker] = useState<boolean>();
  const [showErrors, setShowErrors] = useState(false);
  const {
    sourceLanguage,
    targetLanguages,
    deadline,
    files,
    totalWordCount,
    handleSetTargetLanguages,
    handleSetSourceLanguage,
    handleSetDeadline,
    setFiles,
    handleSetTotalWordCount,
  } = useOrderInfo();
  const { getLogoutOrderToken } = useOrderBooking();
  const channelsRef = useRef<OCRChannel[]>([]);
  const intl = useIntl();
  const isDeadlineValid = deadline ? isBefore(deadline, addDays(new Date(), 2)) : false;
  const schema = yup.object().shape({
    totalWordCount:
      files.length <= 0 && !totalWordCount
        ? yup.string().required(
            intl.formatMessage(
              { id: translationKeys.form_error_required },
              {
                fieldName: intl.formatMessage({
                  id: translationKeys.order_info_word_count_label,
                }),
              },
            ),
          )
        : yup.string().notRequired(),
  });

  const initialValues = {
    totalWordCount: totalWordCount != 0 ? totalWordCount.toString() : '',
  };

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema: schema,
    validate: () => {
      validateForm();
    },
    onSubmit: () => {
      return handleSubmit();
    },
  });

  const handleDeadlineChange = (deadline: Date) => {
    handleSetDeadline(deadline);
    setActiveDatePicker(false);
  };

  const getDatePickerValue = () => {
    if (!deadline) {
      return '';
    }
    return format(new Date(deadline), 'dd/MM/yyyy');
  };

  const languageOptions = (languages: Language[]) => {
    return (
      languages.map((language) => {
        return { id: language.id, label: language.name || '', key: language.id.toString() };
      }) || []
    );
  };

  const sourceLanguageOptions = languageOptions(
    languages.filter((language) => {
      return !targetLanguages.includes(language.id) && language.has_written_format;
    }),
  );

  const targetLanguageOptions = languageOptions(
    languages.filter((language) => language.id !== sourceLanguage && language.has_written_format),
  );

  const handleNewFiles = (newFiles: LogoutOrderFile[]) => {
    const updatedNewFiles: LogoutOrderFile[] = newFiles.map((newFile) => {
      return {
        ...newFile,
        wordCount: 0,
        ocrWaitCount: {
          count: 0,
          isComplete: false,
        },
        uploadProgress: 0,
        uploadStatus: 'selected',
      };
    });
    setFiles((currentFiles) => [...currentFiles, ...updatedNewFiles]);
    updatedNewFiles.forEach((file) => handleUpload(file));
  };

  const handleUpload = async (file: LogoutOrderFile): Promise<void> => {
    getLogoutOrderToken().then(async (token) => {
      if (token) {
        const formData = new FormData();
        formData.append('file', objectToFile(file));
        formData.append('logout_token', token.toString());

        const { data, error } = await api.uploadFile(formData, (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1));

          setFiles((currentFiles) => {
            return currentFiles.map((currentFile) =>
              currentFile.name === file.name
                ? {
                    ...currentFile,
                    uploadProgress: progress,
                    uploadStatus: progress > 0 && progress <= 100 ? 'uploading' : 'finished',
                  }
                : currentFile,
            );
          });
        });

        if (data) {
          setFiles((currentFiles) => {
            return currentFiles.map((currentFile) =>
              currentFile.name === file.name
                ? {
                    ...currentFile,
                    uploadProgress: 100,
                    uploadStatus: 'calculatingOCR',
                    ocrWaitCount: { count: 60, isComplete: false },
                    notification: undefined,
                    id: data.id,
                  }
                : currentFile,
            );
          });
          sendGAEvent(GAEventType.FileUploaded);

          const cable = ActionCable.createConsumer(
            `${process.env.REACT_APP_WEB_SOCKET_URL}?logout_token=${token}&industry=translation`,
          );

          const logoutOcrResultChannel = cable.subscriptions.create(
            {
              channel: 'LogoutOcrResultChannel',
              logout_token: token,
              attachment_id: data?.id,
            },
            {
              received: (data: any) => {
                if (data) {
                  const errorMessage = (currentFile: LogoutOrderFile) => {
                    if (currentFile?.wordCount) {
                      return '';
                    }

                    if (data.errors || data.wordsCount == 0) {
                      return intl.formatMessage({
                        id: translationKeys.order_info_error_estimating_word_count,
                      });
                    }

                    return '';
                  };

                  setFiles((currentFiles) => {
                    return currentFiles.map((currentFile) =>
                      currentFile.name === file.name
                        ? {
                            ...currentFile,
                            uploadStatus: 'finished',
                            notification: errorMessage(currentFile)
                              ? {
                                  type: 'warning',
                                  message: errorMessage(currentFile),
                                }
                              : undefined,
                            wordCount:
                              currentFile?.wordCount ||
                              (data.wordsCount != 0 ? data.wordsCount : currentFile?.wordCount),
                            ocrWaitCount: { count: 0, isComplete: true },
                          }
                        : currentFile,
                    );
                  });
                  setFiles((currentFiles) => {
                    handleSetTotalWordCount(
                      currentFiles.reduce((accumulator, currentValue) => {
                        return accumulator + (currentValue.wordCount || 0);
                      }, 0),
                    );
                    return currentFiles;
                  });
                  logoutOcrResultChannel?.unsubscribe();
                }
              },
            },
          );
          const newChannel = {
            fileId: data.id,
            channel: logoutOcrResultChannel,
            uid: file.uid,
          };
          channelsRef.current.push(newChannel);
        }

        if (error) {
          setFiles((currentFiles) => {
            return currentFiles.map((currentFile) =>
              currentFile.name === file.name
                ? {
                    ...currentFile,
                    uploadProgress: 0,
                    uploadStatus: 'failed',
                    notification: {
                      type: 'error',
                      message: intl.formatMessage({
                        id: translationKeys.order_info_error_upload_failed,
                      }),
                    },
                  }
                : currentFile,
            );
          });
        }
      }
    });
  };

  const deleteFile = async (fileId: number) => {
    await getLogoutOrderToken().then(async (token) => {
      await api.deleteFile(fileId.toString(), {
        logout_token: token,
      });
    });
  };

  const handleRemove = (file: LogoutOrderFile) => {
    const fileToDelete = files.find((currentFile) => currentFile.uid === file.uid);
    if (fileToDelete?.id) {
      channelsRef.current.some((channel) => {
        if (file.uid == channel.uid) channel.channel.unsubscribe();
      });

      deleteFile(fileToDelete.id).then(() => {
        setFiles((currentFiles) =>
          currentFiles.filter((currentFile) => currentFile.uid !== file.uid),
        );
      });
    }
    if (!fileToDelete?.id) {
      setFiles((currentFiles) =>
        currentFiles.filter((currentFile) => currentFile.uid !== file.uid),
      );
    }
  };

  const handleDownload = (file: LogoutOrderFile) => {
    const blob = new Blob([file.data], { type: file.type });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = file.name;
    link.click();

    URL.revokeObjectURL(url);
  };

  const navigateToContactInfo = () => {
    navigate('/contact');
  };

  const handleFilesValidation = () => {
    let isValid = true;

    setFiles((currentFiles) => {
      return currentFiles.map((currentFile) => {
        if (
          currentFile.uploadStatus === 'finished' ||
          currentFile.uploadStatus === 'calculatingOCR'
        ) {
          if (currentFile.wordCount === 0 || !currentFile.wordCount) {
            isValid = false;
            return {
              ...currentFile,
              notification: {
                type: 'error',
                message: intl.formatMessage({
                  id: translationKeys.order_info_error_empty_word_count,
                }),
              },
            };
          } else {
            return { ...currentFile, notification: undefined };
          }
        } else {
          return currentFile;
        }
      });
    });
    return isValid;
  };

  const validateForm = () => {
    setShowErrors(false);
    if (
      !handleFilesValidation() ||
      !sourceLanguage ||
      (targetLanguages && targetLanguages.length <= 0) ||
      (totalWordCount >= 10000 && files && files.length <= 0)
    ) {
      setShowErrors(true);
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    navigateToContactInfo();
  };

  useEffect(() => {
    getLogoutOrderToken(true);

    sendGAEvent(GAEventType.PageLanding);
  }, []);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setFiles((currentFiles) => {
        return currentFiles.map((file) => {
          if (
            file.uploadStatus != 'uploading' &&
            file.uploadStatus != 'selected' &&
            file.ocrWaitCount.count == 0 &&
            !file.ocrWaitCount.isComplete
          ) {
            return {
              ...file,
              ocrWaitCount: { ...file.ocrWaitCount, isComplete: true },
              notification: {
                type: 'warning',
                message: intl.formatMessage({
                  id: translationKeys.order_info_error_estimating_word_count_taking_time,
                }),
              },
            };
          }
          return !file?.ocrWaitCount.isComplete
            ? {
                ...file,
                ocrWaitCount: { ...file.ocrWaitCount, count: file.ocrWaitCount.count - 1 },
              }
            : file;
        });
      });
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  useEffect(() => {
    return () => {
      channelsRef.current.forEach((channel) => {
        channel.channel.unsubscribe();
      });
    };
  }, []);

  const renderFileActions = (file: LogoutOrderFile) => {
    if (file.uploadStatus === 'finished') {
      return [
        {
          id: FileActionType.Download,
          text: intl.formatMessage({ id: translationKeys.order_info_actions_download }),
          icon: faArrowDownToLine,
        },
        {
          id: FileActionType.Delete,
          text: intl.formatMessage({ id: translationKeys.order_info_actions_delete }),
          icon: faTrash,
        },
      ];
    }
    return [
      {
        id: FileActionType.Delete,
        text: intl.formatMessage({ id: translationKeys.order_info_actions_delete }),
        icon: faTrash,
      },
    ];
  };

  const renderSubtitle = (file: LogoutOrderFile) => {
    if (file.uploadStatus === 'uploading') {
      return (
        <span className={styles.status}>
          {intl.formatMessage({ id: translationKeys.order_info_uploading_status_text })}
        </span>
      );
    }

    if (file.uploadStatus === 'calculatingOCR') {
      return (
        <span className={styles.status}>
          {intl.formatMessage({ id: translationKeys.order_info_calculating_ocr_status_text })}
        </span>
      );
    }
    return <span className={styles.size}>{formatFileSize(file.size)}</span>;
  };

  const renderPreview = (file: LogoutOrderFile) => {
    if (file.uploadStatus === 'uploading') {
      return <CircularProgressBar percentage={file.uploadProgress} />;
    }

    if (file.uploadStatus === 'calculatingOCR' && file.ocrWaitCount.count != 0) {
      return <Spinner className={styles.spinner} />;
    }

    return file.type.split('/')[0] === 'image' ? (
      <img className={styles.previewImage} src={file.preview} alt="File item preview" />
    ) : (
      <img className={styles.fileIcon} src={fileIconIllustration} alt="File item icon" />
    );
  };

  const renderRightSection = (file: LogoutOrderFile) => {
    if (
      ['finished', 'failed', 'calculatingOCR'].includes(file.uploadStatus) &&
      file.ocrWaitCount.isComplete
    ) {
      return (
        <div className={styles.wordCount}>
          <TextField
            value={file.wordCount || ''}
            onChange={(e) => {
              const wordCount = Number(e.currentTarget.value);
              setFiles((currentFile) => {
                return currentFile.map((currentFile) =>
                  currentFile.uid === file.uid
                    ? { ...currentFile, wordCount: wordCount > 0 ? wordCount : undefined }
                    : currentFile,
                );
              });
              setFiles((currentFiles) => {
                handleSetTotalWordCount(
                  currentFiles.reduce((accumulator, currentValue) => {
                    return accumulator + (currentValue.wordCount || 0);
                  }, 0),
                );
                return currentFiles;
              });
            }}
            type="number"
          />
          <span className={styles.wordCountText}>
            {intl.formatMessage({ id: translationKeys.order_info_words_label })}
          </span>
        </div>
      );
    }
  };

  return (
    <div className={styles.content}>
      <div className={styles.getQuoteSection}>
        <h3 className={styles.heading}>
          <FormattedMessage id={translationKeys.get_quote_title} />
        </h3>
        <span className={styles.description}>
          <FormattedMessage id={translationKeys.get_quote_subtitle} />
        </span>
      </div>
      <div className={styles.whatYouNeedSection}>
        <h3 className={styles.heading} data-testid="form-title">
          <FormattedMessage id={translationKeys.order_info_form_title} />
        </h3>
        <span className={styles.description} data-testid="form-subtitle">
          <FormattedMessage id={translationKeys.order_info_form_subtitle} />
        </span>
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <div className={styles.customerType}>
          <RadioOptions
            label={intl.formatMessage({ id: translationKeys.order_info_customer_type_label })}
            selected={selectedCustomerType}
            options={[
              {
                id: CustomerType.Enterprise,
                title: intl.formatMessage({
                  id: translationKeys.order_info_customer_type_enterprise_option,
                }),
                icon: <FontAwesomeIcon icon={faBuilding} />,
              },
              {
                id: CustomerType.Private,
                title: intl.formatMessage({
                  id: translationKeys.order_info_customer_type_private_option,
                }),
                icon: <FontAwesomeIcon icon={faUser} />,
              },
            ]}
            onSelect={(id) => setSelectedCustomerType(id as CustomerType)}
          />
        </div>

        {selectedCustomerType === CustomerType.Enterprise && (
          <>
            <div className={styles.sourceLanguage} data-testid="source-language-dropdown">
              <SearchDropdown
                options={sourceLanguageOptions}
                placeholder={intl.formatMessage({
                  id: translationKeys.order_info_language_dropdown_placeholder,
                })}
                label={intl.formatMessage({
                  id: translationKeys.order_info_source_language_label,
                })}
                size="large"
                required={true}
                errorText={
                  showErrors && !sourceLanguage
                    ? intl.formatMessage({
                        id: translationKeys.order_info_source_language_required,
                      })
                    : undefined
                }
                onChange={(languages) => {
                  if (languages && languages.length > 0) {
                    handleSetSourceLanguage(languages[0].id);
                  } else {
                    handleSetSourceLanguage(null);
                  }
                }}
              />
            </div>
            <div className={styles.targetLanguages} data-testid="target-languages-dropdown">
              <SearchDropdown
                options={targetLanguageOptions}
                placeholder={intl.formatMessage({
                  id: translationKeys.order_info_language_dropdown_placeholder,
                })}
                label={intl.formatMessage({
                  id: translationKeys.order_info_target_languages_label,
                })}
                size="large"
                multiple
                required={true}
                errorText={
                  showErrors && targetLanguages && targetLanguages.length <= 0
                    ? intl.formatMessage({
                        id: translationKeys.order_info_target_languages_required,
                      })
                    : undefined
                }
                onChange={(languages) => {
                  if (languages && languages.length > 0) {
                    handleSetTargetLanguages(
                      languages.map((language: SearchDropdownMenuOption) => {
                        return language.id;
                      }),
                    );
                  } else {
                    handleSetTargetLanguages([]);
                  }
                }}
              />
            </div>
            <div className={styles.deadline}>
              <TextField
                data-testid="deadline-datepicker-input"
                label={intl.formatMessage({
                  id: translationKeys.order_info_deadline_date_picker_label,
                })}
                placeholder={intl.formatMessage({
                  id: translationKeys.order_info_deadline_date_picker_placeholder,
                })}
                icon={<FontAwesomeIcon className={styles.calendarIcon} icon={faCalendar} />}
                size="large"
                onFocus={() => setActiveDatePicker(true)}
                onClick={() => setActiveDatePicker(true)}
                value={getDatePickerValue()}
                onChange={() => handleSetDeadline(null)}
                type="search"
              />

              {activeDatePicker && (
                <DatePicker
                  data-testid="date-picker-calendar"
                  selected={deadline || undefined}
                  className={styles.datePicker}
                  singleDate={true}
                  minDate={new Date()}
                  onClose={() => setActiveDatePicker(false)}
                  onChange={(start: Date) => handleDeadlineChange(start)}
                />
              )}
              {isDeadlineValid && (
                <div className={styles.banner}>
                  <Banner
                    data-testid="deadline-error-banner"
                    variant="error"
                    text={
                      <FormattedMessage
                        id={translationKeys.order_info_deadline_error_banner}
                        defaultMessage={translationKeys.order_info_deadline_error_banner}
                        values={{
                          link: (
                            <a
                              href="mailto: oversettelse@salita.no"
                              onClick={() => sendGAEvent(GAEventType.ContactEmailClicked)}
                            >
                              {'oversettelse@salita.no'}
                            </a>
                          ),
                        }}
                      />
                    }
                  />
                </div>
              )}
            </div>
            <h3 className={styles.fileUploaderTitle}>
              <FormattedMessage id={translationKeys.order_info_file_uploader_label} />
            </h3>
            <div className={styles.fileUploader}>
              <FileSelector
                data-testid="order-attachment-uploader"
                selectedFiles={files}
                onSelection={(newFiles: FileState[]) => {
                  handleNewFiles(newFiles as LogoutOrderFile[]);
                }}
              />

              <div className={styles.fileItemsContainer}>
                {files.map((file) => (
                  <div key={file.uid} className={styles.fileItem}>
                    <DetailItem
                      data-testid="file-item"
                      variant={file.notification?.type}
                      preview={renderPreview(file)}
                      title={<span className={styles.name}>{file.name}</span>}
                      subtitle={renderSubtitle(file)}
                      actions={renderFileActions(file)}
                      onActionSelect={(selection) => {
                        if (selection === FileActionType.Delete) {
                          return handleRemove(file);
                        }
                        if (selection === FileActionType.Download) {
                          return handleDownload(file);
                        }
                      }}
                    >
                      {renderRightSection(file)}
                    </DetailItem>
                    {file.notification?.message && (
                      <div className={styles.notificationContainer}>
                        <InlineError
                          text={file.notification?.message}
                          variant={file.notification?.type}
                        />
                        {file.uploadStatus === 'failed' && (
                          <Button
                            variant="transparent"
                            size="small"
                            onClick={() => handleUpload(file)}
                            data-testid="retry-button"
                          >
                            {intl.formatMessage({ id: translationKeys.order_info_retry_button })}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>

              {files.length <= 0 && (
                <>
                  <div className={styles.divider}>
                    {
                      <FormattedMessage
                        id={translationKeys.order_info_word_count_or_divider_text}
                      />
                    }
                  </div>
                  <TextField
                    data-testid="word-count-field"
                    label={intl.formatMessage({
                      id: translationKeys.order_info_word_count_label,
                    })}
                    placeholder={intl.formatMessage({
                      id: translationKeys.order_info_word_count_placeholder,
                    })}
                    type="number"
                    size="large"
                    required
                    onChange={(e) => handleSetTotalWordCount(Number(e.currentTarget.value))}
                    value={totalWordCount != 0 ? totalWordCount : ''}
                    errorText={
                      !totalWordCount && formik.errors.totalWordCount
                        ? formik.errors.totalWordCount
                        : undefined
                    }
                  />
                  <p className={styles.hint}>
                    {<FormattedMessage id={translationKeys.order_info_word_count_input_hint} />}
                  </p>
                </>
              )}
              {totalWordCount >= 10000 && files && files.length <= 0 && (
                <div className={styles.banner}>
                  <Banner
                    data-testid="word-count-error-banner"
                    variant={'error'}
                    text={
                      <FormattedMessage
                        id={translationKeys.order_info_max_word_count_error}
                        key={translationKeys.order_info_max_word_count_error}
                        values={{
                          email: (
                            <a
                              href="mailto: oversettelse@salita.no"
                              onClick={() => sendGAEvent(GAEventType.ContactEmailClicked)}
                            >
                              {'oversettelse@salita.no'}
                            </a>
                          ),
                          maxWords: '10,000',
                        }}
                      />
                    }
                  />
                </div>
              )}
            </div>
            <div>
              <Button
                data-testid="next-button"
                className={styles.nextButton}
                size="large"
                variant="primary"
                fullWidth={true}
                type="submit"
              >
                <FormattedMessage id={translationKeys.order_info_next_button} />
              </Button>
              <span className={styles.orderNote}>
                <FormattedMessage id={translationKeys.logged_out_booking_prices_note} />
              </span>
            </div>
          </>
        )}

        {selectedCustomerType === CustomerType.Private && (
          <Banner
            variant="error"
            text={intl.formatMessage({
              id: translationKeys.order_info_private_customer_not_supported_error,
            })}
          />
        )}
      </Form>
    </div>
  );
}

export default OrderInformation;
