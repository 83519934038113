import React, { useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useBeforeUnload, useLocation, useNavigate } from 'react-router-dom';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Banner,
  Button,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { GAEventType, sendGAEvent } from '../../helpers/GoogleAnalytics';
import useDebounce from '../../hooks/useDebounce';
import { useApi } from '../../providers/ApiProvider';
import { useLanguages } from '../../providers/LanguagesProvider';
import { useOrderBooking } from '../../providers/OrderBookingProvider';
import { useOrderInfo } from '../../providers/OrderInfoProvider';
import translationKeys from '../../translations/translationKeys';
import { BookingReference } from '../../types/BookingReference';
import { Department } from '../../types/Department';
import Enterprise from '../../types/Enterprise';
import { LoggedOutCustomerType } from '../../types/LogoutCustomer';
import { InvoiceUpdateRequestBody, OrderEstimateResponse } from '../../types/Order';
import InvoiceSummary from './InvoiceSummary/InvoiceSummary';
import styles from './BookingEstimate.module.scss';

enum SendInvoiceToOption {
  MyEnterprise = 'MY_ENTERPRISE',
  OtherEnterprise = 'OTHER_ENTERPRISE',
}

interface Props {
  updateIsEstimateVisible: (isVisible: boolean) => void;
}

interface FormValues {
  enterprise: string;
  departmentName: string;
  buyerReference: string;
  orderReference: string;
  addToDepartment: boolean;
}

const BookingEstimate = (props: Props) => {
  const { updateIsEstimateVisible } = props;

  const api = useApi();
  const [updateFinanceInfoRequestLoading, setUpdateFinanceInfoRequestLoading] =
    useState<boolean>(false);
  const [searchOrgNumberLoading, setSearchOrgNumberLoading] = useState<boolean>(false);
  const [orderEstimateLoading, setOrderEstimateLoading] = useState<boolean>(false);
  const { selectedEnterprise } = useOrderInfo();
  const intl = useIntl();
  const [whitelistedEnterpriseOptions, setWhitelistedEnterpriseOptions] = useState<
    SearchDropdownMenuOption[]
  >([]);
  const [selectedWhitelistedEnterprise, setSelectedWhitelistedEnterprise] = useState<
    SearchDropdownMenuOption[]
  >([]);
  const [orgNumberOptions, setOrgNumberOptions] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedOrgNumber, setSelectedOrgNumber] = useState<SearchDropdownMenuOption>();
  const [enterpriseDepartments, setEnterpriseDepartments] = useState<SearchDropdownMenuOption[]>(
    [],
  );
  const [allDepartments, setAllDepartments] = useState<Department[]>([]);
  const [selectedEnterpriseDepartment, setSelectedEnterpriseDepartment] =
    useState<SearchDropdownMenuOption>();
  const [sendInvoiceTo, setSendInvoiceTo] = useState<SearchDropdownMenuOption[]>();
  const [selectedEnterpriseObject, setSelectedEnterpriseObject] = useState<
    SearchDropdownMenuOption[]
  >([]);
  const [apiErrors, setApiErrors] = useState<string>();
  const { getLanguageById } = useLanguages();

  const onChangeOrgNumber = (orgNumbers: SearchDropdownMenuOption[] | null) => {
    if (orgNumbers && orgNumbers.length > 0) {
      setSelectedOrgNumber(orgNumbers[0]);
    } else {
      setSelectedOrgNumber(undefined);
    }
  };

  const sendInvoiceToOptions = [
    {
      id: 1,
      key: SendInvoiceToOption.MyEnterprise,
      label: intl.formatMessage({
        id: translationKeys.invoicing_details_send_invoice_to_my_enterprise,
      }),
    },
    {
      id: 0,
      key: SendInvoiceToOption.OtherEnterprise,
      label: intl.formatMessage({
        id: translationKeys.invoicing_details_send_invoice_to_other_enterprise,
      }),
    },
  ];
  const noneDepartmentOption = {
    id: 0,
    label: intl.formatMessage({
      id: translationKeys.invoicing_details_department_none_option,
    }),
    key: 'none',
  };
  const [showErrors, setShowErrors] = useState(false);
  const { order, customerType, getLogoutOrderToken, handleSetOrder } = useOrderBooking();
  const [orderEstimate, setOrderEstimate] = useState<OrderEstimateResponse>();
  const [bookingReference, setBookingReference] = useState<BookingReference>();
  const [isBookingReferenceValid, setIsBuyerReferenceValid] = useState<boolean>(true);
  const [isOrderReferenceValid, setIsOrderReferenceValid] = useState<boolean>(true);
  const showNewDepartment = () => {
    if (customerType == LoggedOutCustomerType.New) {
      return selectedEnterpriseDepartment && selectedEnterpriseDepartment.key === 'none';
    }
    return (
      (selectedEnterpriseDepartment && selectedEnterpriseDepartment.key === 'none') ||
      enterpriseDepartments.length <= 0
    );
  };

  const schema = yup.object().shape({
    enterprise: yup.string().notRequired(),
    departmentName: showNewDepartment()
      ? yup.string().required(
          intl.formatMessage(
            { id: translationKeys.form_error_required },
            {
              fieldName: intl.formatMessage({
                id: translationKeys.invoicing_details_department_text,
              }),
            },
          ),
        )
      : yup.string().notRequired(),
    orderReference: yup.string().notRequired(),
    buyerReference: yup.string().notRequired(),
    addToDepartment: yup.boolean().notRequired(),
  });
  const initialValues: FormValues = {
    enterprise: '',
    departmentName: '',
    buyerReference: '',
    orderReference: '',
    addToDepartment: false,
  };

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema: schema,
    validate: () => {
      validateForm();
    },
    onSubmit: (values) => {
      return handleSubmit(values);
    },
  });
  const navigate = useNavigate();
  const location = useLocation();
  const debounceEnterpriseSearch = useDebounce(300);

  const navigateToFinishedBooking = () => {
    navigate('/order-finished');
  };

  const showSendInvoiceTo = () => {
    return (
      customerType === LoggedOutCustomerType.Existing ||
      customerType === LoggedOutCustomerType.Whitelisted
    );
  };

  const disableOrgNumber = () => {
    if (customerType !== LoggedOutCustomerType.New) {
      return !(sendInvoiceTo && sendInvoiceTo[0].key === SendInvoiceToOption.OtherEnterprise);
    }

    return true;
  };

  const showOrgNumber = () => {
    if (customerType !== LoggedOutCustomerType.New) {
      return sendInvoiceTo && sendInvoiceTo[0].key === SendInvoiceToOption.OtherEnterprise;
    }

    return true;
  };

  const showDepartmentSection = () => {
    if (customerType === LoggedOutCustomerType.Whitelisted) {
      return selectedWhitelistedEnterprise[0]?.id;
    }
    return customerType === LoggedOutCustomerType.Existing;
  };

  const validateBuyerReference = (buyerReference: string) => {
    if (bookingReference?.bookingReferenceRegex) {
      const regex = new RegExp(bookingReference?.bookingReferenceRegex);
      setIsBuyerReferenceValid(regex.test(buyerReference));
    } else {
      setIsBuyerReferenceValid(true);
    }
  };

  const validateOrderReference = (orderReference: string) => {
    if (bookingReference?.paymentBookingReferenceRegex) {
      const regex = new RegExp(bookingReference?.paymentBookingReferenceRegex);
      setIsOrderReferenceValid(regex.test(orderReference));
    } else {
      setIsOrderReferenceValid(true);
    }
  };

  const getOrgInformation = () => {
    if (!selectedEnterpriseObject || selectedEnterpriseObject.length === 0) {
      return {
        orgNumber: '',
        orgName: '',
      };
    }

    const selectedOrg = selectedEnterpriseObject[0];

    return {
      orgNumber: selectedOrg.id.toString(),
      orgName: selectedOrg.label,
    };
  };

  const getOrgPaymentMethod = useCallback(() => {
    if (selectedOrgNumber?.id) {
      return { orgNumber: selectedOrgNumber.id.toString(), orgName: selectedOrgNumber.label };
    } else if (selectedWhitelistedEnterprise[0]?.key) {
      return {
        orgNumber: selectedWhitelistedEnterprise[0].key.toString(),
        orgName: selectedWhitelistedEnterprise[0].label,
      };
    } else {
      return { orgNumber: order?.enterprise.orgNumber, orgName: order?.enterprise.name };
    }
  }, [selectedOrgNumber, sendInvoiceTo, selectedWhitelistedEnterprise]);

  const getInvoiceHeaderType = () => {
    if (getOrgPaymentMethod().orgNumber && !orderEstimateLoading) {
      if (orderEstimate?.frame_agreement) {
        return 'frameAgreement';
      }
      return orderEstimate?.visible_for_customer ? 'showQuote' : 'hideQuote';
    } else {
      return 'noOrgNumber';
    }
  };

  const handleDepartmentChange = (departments: SearchDropdownMenuOption[]) => {
    const isDepartmentSelected = departments && departments.length > 0;
    if (isDepartmentSelected) {
      setSelectedEnterpriseDepartment(departments[0]);
      const selectedDepartment = departments[0];
      const departmentDetails = allDepartments.find(
        (department) => department.id === selectedDepartment.id,
      );
      if (departmentDetails && departmentDetails.paymentMethod) {
        setSelectedOrgNumber({
          id: Number(departmentDetails.paymentMethod.orgNumber),
          label: departmentDetails.paymentMethod.orgName,
          key: departmentDetails.paymentMethod.orgNumber,
        });
        setSendInvoiceTo([sendInvoiceToOptions[0]]);
      } else {
        setSendInvoiceTo([sendInvoiceToOptions[1]]);
      }
      if (departmentDetails) {
        formik.setValues({
          ...formik.values,
          buyerReference: departmentDetails.defaultBookingReference
            ? departmentDetails.defaultBookingReference
            : formik.values.buyerReference,
          orderReference: departmentDetails.defaultPaymentBookingReference
            ? departmentDetails.defaultPaymentBookingReference
            : formik.values.orderReference,
        });
      }
    } else {
      setSelectedEnterpriseDepartment(undefined);
      setSendInvoiceTo(undefined);
      setSelectedOrgNumber(undefined);
      formik.setValues({
        ...formik.values,
        buyerReference: '',
        orderReference: '',
      });
    }
  };

  const findSelectedEnterprise = (
    selectedEnterpriseId: number | undefined,
    combinedEnterpriseOptions: SearchDropdownMenuOption[],
  ): SearchDropdownMenuOption[] => {
    if (selectedEnterpriseId !== undefined) {
      const foundEnterprise = combinedEnterpriseOptions.find((enterprise) => {
        return enterprise.id === selectedEnterpriseId;
      });
      return foundEnterprise ? [foundEnterprise] : [];
    }

    return [];
  };

  const searchOrgNumber = async (query: string, selectedEnterpriseId?: number) => {
    const { data } = await api.publicSearchEnterprises(
      {
        query,
        search_brreg: true,
      },
      setSearchOrgNumberLoading,
    );

    if (data && (data.enterprises || data.brregSearch)) {
      const combinedEnterpriseOptions =
        data.enterprises
          .concat(data.brregSearch)
          .reduce<Enterprise[]>((accumulator, current) => {
            if (!accumulator.find((enterprise) => enterprise.orgNumber === current.orgNumber)) {
              accumulator.push(current);
            }
            return accumulator;
          }, [])
          .map((enterprise) => {
            return {
              id: Number(enterprise.orgNumber),
              label: enterprise.name,
              key: enterprise.id.toString(),
              subtitle: `Org. nr. ${enterprise.orgNumber}`,
            };
          }) || [];

      setOrgNumberOptions(combinedEnterpriseOptions);

      const selectedEnterpriseObjectFromRequest = findSelectedEnterprise(
        selectedEnterpriseId,
        combinedEnterpriseOptions,
      );

      if (selectedEnterpriseObjectFromRequest)
        setSelectedEnterpriseObject(selectedEnterpriseObjectFromRequest);
    }
  };

  useEffect(() => {
    if (selectedEnterprise) searchOrgNumber(selectedEnterprise.toString(), selectedEnterprise);
  }, []);

  const getMyWhitelistedEnterprises = async () => {
    const { data } = await api.getWhitelistedEnterprises({
      email: order?.owner.email,
    });

    if (data?.enterprises) {
      const enterpriseOptions =
        data.enterprises.map((enterprise) => {
          return {
            id: enterprise.id,
            label: enterprise.name,
            key: enterprise.orgNumber,
          };
        }) || [];

      setWhitelistedEnterpriseOptions(enterpriseOptions);
      if (enterpriseOptions && enterpriseOptions.length === 1) {
        setSelectedWhitelistedEnterprise(enterpriseOptions);
      }
    }
  };
  const getEnterpriseDepartment = async (enterpriseId: number) => {
    const { data } = await api.getEnterpriseDepartments(enterpriseId.toString(), {
      verbose: false,
    });

    if (data?.departments && data.departments.length) {
      const enterpriseDepartmentOptions =
        data.departments.map((department) => {
          return {
            id: Number(department.id),
            label: department.name,
            key: department.id.toString(),
          };
        }) || [];
      setEnterpriseDepartments([...enterpriseDepartmentOptions, noneDepartmentOption]);
      setAllDepartments(data.departments);
    }
  };

  const getOrderEstimate = async () => {
    if (getOrgPaymentMethod().orgNumber && order) {
      getLogoutOrderToken().then(async (token) => {
        const { data } = await api.getOrderEstimate(
          order.id.toString(),
          {
            token: token,
            booker: {
              email: order?.owner.email,
            },
            order: {
              id: order?.id,
              logged_out_booker_org_number: getOrgInformation().orgNumber,
              logged_out_booker_payment_org_number: getOrgPaymentMethod().orgNumber,
            },
          },
          setOrderEstimateLoading,
        );

        if (data) {
          setOrderEstimate(data);

          sendGAEvent(GAEventType.OfferPresented, {
            value: data.amount || '-',
          });
        }
      });
    }
  };

  const getOrgReferencesRegex = async (orgNumber: string) => {
    const { data } = await api.getOrgReferencesRegex(orgNumber);

    if (data?.regularExpressions) {
      setBookingReference(data.regularExpressions);
    }
  };

  const validateForm = () => {
    setShowErrors(false);
    if (customerType === 'new') {
      if (!selectedEnterpriseObject || (!showNewDepartment() && !selectedEnterpriseDepartment)) {
        setShowErrors(true);
        return false;
      }
    }

    if (customerType === 'existing') {
      if ((!showNewDepartment() && !selectedEnterpriseDepartment) || !sendInvoiceTo) {
        setShowErrors(true);
        return false;
      } else if (
        sendInvoiceTo &&
        sendInvoiceTo[0].key === SendInvoiceToOption.OtherEnterprise &&
        !selectedEnterpriseObject
      ) {
        setShowErrors(true);
        return false;
      }
    }

    if (customerType === LoggedOutCustomerType.Whitelisted) {
      if (
        (!showNewDepartment() && !selectedEnterpriseDepartment) ||
        !selectedWhitelistedEnterprise ||
        !sendInvoiceTo
      ) {
        setShowErrors(true);
        return false;
      }

      if (
        sendInvoiceTo &&
        sendInvoiceTo[0].key === SendInvoiceToOption.OtherEnterprise &&
        !selectedEnterpriseObject
      ) {
        setShowErrors(true);
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (values: FormValues) => {
    if (!validateForm()) {
      return;
    }

    await getLogoutOrderToken().then(async (token) => {
      if (order && token) {
        const requestBody: InvoiceUpdateRequestBody = {
          token: token,
          order: {
            booking_reference: values.buyerReference,
            payment_booking_reference: values.orderReference,
            add_to_department: values.addToDepartment,
            logged_out_booker_org_number: getOrgInformation().orgNumber.trim(),
            logged_out_booker_org_name: getOrgInformation().orgName,
            logged_out_booker_payment_org_number: getOrgPaymentMethod().orgNumber?.trim(),
            logged_out_booker_payment_org_name: getOrgPaymentMethod()?.orgName,
            estimated_quote: {
              amount: orderEstimate?.amount || '',
              visible_for_customer: orderEstimate?.visible_for_customer || false,
              frame_agreement: orderEstimate?.frame_agreement || false,
            },
          },
        };

        if (showNewDepartment()) requestBody.order.department = { name: values.departmentName };
        else requestBody.order.department_id = selectedEnterpriseDepartment?.id;

        const { data, error } = await api.updateFinanceInfo(
          order.id.toString(),
          requestBody,
          setUpdateFinanceInfoRequestLoading,
        );

        if (data) {
          handleSetOrder(data.order);
          sendGAEvent(GAEventType.OrderPlaced, {
            order_id: order.id,
            value: orderEstimate?.amount || undefined,
            source_language: getLanguageById(order.sourceLanguageId),
            target_languages: order?.targetLanguages.map((languageId) => {
              return getLanguageById(languageId);
            }),
          });

          if (orderEstimate) {
            updateIsEstimateVisible(orderEstimate.visible_for_customer);
          }

          navigateToFinishedBooking();
        }

        if (error) {
          let errorText = intl.formatMessage({
            id: translationKeys.logged_out_booking_default_error_message,
          });

          if (typeof error.text === 'string') {
            errorText = error.text;
          } else if (typeof error.text === 'object' && 'global!' in error.text) {
            const globalErrors = (error.text as { 'global!': string[] })['global!'];
            errorText = globalErrors.length > 0 ? globalErrors[0] : errorText;
          }

          setApiErrors(errorText);
        }
      }
    });
  };

  useBeforeUnload(() => {
    const destinationPath = '/order-finished';
    if (location.pathname !== destinationPath && getOrgPaymentMethod().orgNumber) {
      sendGAEvent(GAEventType.OrderAbandoned, {
        order_id: order?.id,
        org_number: getOrgPaymentMethod().orgNumber,
        email: order?.owner.email,
      });
    }
  });

  useEffect(() => {
    if (order?.enterprise.id) getEnterpriseDepartment(order.enterprise.id);
    if (customerType === LoggedOutCustomerType.Whitelisted) getMyWhitelistedEnterprises();
    if (customerType === LoggedOutCustomerType.Existing) {
      getOrderEstimate();
      setSendInvoiceTo([sendInvoiceToOptions[0]]);
    } else {
      sendGAEvent(GAEventType.OfferPresented);
    }
  }, []);

  useEffect(() => {
    if (selectedWhitelistedEnterprise[0]?.id) {
      getEnterpriseDepartment(selectedWhitelistedEnterprise[0].id);
      if (!sendInvoiceTo) {
        setSendInvoiceTo([sendInvoiceToOptions[0]]);
        getOrderEstimate();
      }
    }
  }, [selectedWhitelistedEnterprise]);

  useEffect(() => {
    getOrderEstimate();
  }, [selectedOrgNumber, selectedEnterpriseDepartment]);

  useEffect(() => {
    if (customerType == LoggedOutCustomerType.New) {
      setSelectedEnterpriseDepartment(undefined);
      if (selectedOrgNumber?.key) {
        getEnterpriseDepartment(Number(selectedOrgNumber.key));
      } else {
        setEnterpriseDepartments([noneDepartmentOption]);
        setAllDepartments([]);
      }
    }
  }, [selectedOrgNumber]);

  useEffect(() => {
    const orgNumber = getOrgPaymentMethod().orgNumber;
    if (orgNumber) {
      getOrgReferencesRegex(orgNumber);
    } else {
      setBookingReference(undefined);
      setIsBuyerReferenceValid(true);
      setIsOrderReferenceValid(true);
    }
  }, [selectedOrgNumber, getOrgPaymentMethod]);

  useEffect(() => {
    if (bookingReference) {
      validateBuyerReference(formik.values.buyerReference);
      validateOrderReference(formik.values.orderReference);
    }
  }, [bookingReference]);

  return (
    <div className={styles.content}>
      <div className={styles.freeQuoteSection}>
        <h3 className={styles.heading} data-testid="form-title">
          <FormattedMessage id={translationKeys.booking_estimate_form_title} />
        </h3>
        <span className={styles.description} data-testid="form-subtitle">
          <FormattedMessage
            id={translationKeys.booking_estimate_form_subtitle}
            key={translationKeys.booking_estimate_form_subtitle}
            values={{
              email: (
                <a
                  href="mailto: oversettelse@salita.no"
                  onClick={() => sendGAEvent(GAEventType.ContactEmailClicked)}
                >
                  {'oversettelse@salita.no'}
                </a>
              ),
            }}
          />
        </span>
      </div>
      <h4 className={styles.formHeader}>
        <FormattedMessage id={translationKeys.invoicing_details_title} />
      </h4>
      <Form className={styles.form} onSubmit={formik.handleSubmit}>
        {customerType === LoggedOutCustomerType.Existing && (
          <TextField
            placeholder={intl.formatMessage({
              id: translationKeys.invoicing_details_enterprise_label,
            })}
            type="text"
            label={intl.formatMessage({
              id: translationKeys.invoicing_details_enterprise_label,
            })}
            size="large"
            name="enterprise"
            required
            disabled
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={selectedEnterpriseObject[0]?.label}
            errorText={formik.touched.enterprise ? formik.errors.enterprise : undefined}
          />
        )}

        {customerType === LoggedOutCustomerType.Whitelisted && (
          <div data-testid="whitelisted-enterprises-dropdown">
            <SearchDropdown
              selected={selectedEnterpriseObject}
              disabled={whitelistedEnterpriseOptions && whitelistedEnterpriseOptions.length === 1}
              options={whitelistedEnterpriseOptions}
              placeholder={intl.formatMessage({
                id: translationKeys.invoicing_details_org_number_placeholder,
              })}
              label={intl.formatMessage({
                id: translationKeys.invoicing_details_enterprise_label,
              })}
              size="large"
              required
              errorText={
                showErrors && !selectedWhitelistedEnterprise.length
                  ? intl.formatMessage(
                      { id: translationKeys.form_error_required },
                      {
                        fieldName: intl.formatMessage({
                          id: translationKeys.invoicing_details_enterprise_label,
                        }),
                      },
                    )
                  : undefined
              }
              onChange={(enterprises) => {
                if (enterprises && enterprises.length > 0) {
                  setSelectedWhitelistedEnterprise(enterprises);
                } else {
                  setSelectedWhitelistedEnterprise([]);
                }
              }}
            />
          </div>
        )}

        {showDepartmentSection() && (
          <div data-testid="enterprise-department-dropdown">
            {enterpriseDepartments && enterpriseDepartments.length > 0 && (
              <>
                <SearchDropdown
                  options={enterpriseDepartments}
                  placeholder={intl.formatMessage({
                    id: translationKeys.invoicing_details_enterprise_department_placeholder,
                  })}
                  label={intl.formatMessage({
                    id: translationKeys.invoicing_details_enterprise_department_label,
                  })}
                  size="large"
                  required
                  errorText={
                    showErrors && !selectedEnterpriseDepartment
                      ? intl.formatMessage({
                          id: translationKeys.invoicing_details_enterprise_department_required,
                        })
                      : undefined
                  }
                  onChange={(departments) => {
                    handleDepartmentChange(departments || []);
                  }}
                />
              </>
            )}
            {showNewDepartment() && (
              <div className={styles.newDepartment}>
                <TextField
                  placeholder={intl.formatMessage({
                    id: translationKeys.invoicing_details_new_department_placeholder,
                  })}
                  type="text"
                  label={intl.formatMessage({
                    id: translationKeys.invoicing_details_new_department_label,
                  })}
                  size="large"
                  name="departmentName"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.departmentName}
                  errorText={
                    formik.touched.departmentName ? formik.errors.departmentName : undefined
                  }
                />
              </div>
            )}
            <Form.Check
              className={styles.checkbox}
              type="checkbox"
              label={intl.formatMessage({
                id: translationKeys.invoicing_details_add_department_checkbox,
              })}
              name="addToDepartment"
              checked={formik.values.addToDepartment}
              onChange={formik.handleChange}
            />
          </div>
        )}
        {showSendInvoiceTo() && (
          <div data-testid="send-invoice-dropdown">
            <SearchDropdown
              selected={sendInvoiceTo}
              options={sendInvoiceToOptions}
              placeholder={intl.formatMessage({
                id: translationKeys.invoicing_details_send_invoice_label,
              })}
              label={intl.formatMessage({
                id: translationKeys.invoicing_details_send_invoice_label,
              })}
              size="large"
              required={true}
              errorText={
                showErrors && !sendInvoiceTo
                  ? intl.formatMessage({
                      id: translationKeys.invoicing_details_send_invoice_to_required,
                    })
                  : undefined
              }
              onChange={(sendInvoicePreferences) => {
                if (sendInvoicePreferences && sendInvoicePreferences.length > 0) {
                  setSendInvoiceTo(sendInvoicePreferences);
                } else {
                  setSendInvoiceTo(undefined);
                  setSelectedOrgNumber(undefined);
                }
              }}
            />
          </div>
        )}
        {showOrgNumber() && (
          <div className={styles.orgNumber} data-testid="org-number-dropdown">
            <SearchDropdown
              options={orgNumberOptions}
              placeholder={intl.formatMessage({
                id: translationKeys.invoicing_details_org_number_placeholder,
              })}
              label={intl.formatMessage({
                id: translationKeys.invoicing_details_org_number_label,
              })}
              size="large"
              required
              isLoading={searchOrgNumberLoading}
              search
              selected={selectedEnterpriseObject}
              disabled={disableOrgNumber()}
              errorText={
                showErrors && selectedEnterpriseObject.length === 0
                  ? intl.formatMessage({
                      id: translationKeys.invoicing_details_org_number_required,
                    })
                  : undefined
              }
              onSearch={(query: string) => {
                let orgNumber = query;
                orgNumber = orgNumber.replace(/\s/g, '');
                if (orgNumber.length == 9 && !isNaN(parseInt(orgNumber))) {
                  return debounceEnterpriseSearch(() => {
                    searchOrgNumber(orgNumber);
                  });
                }
                return debounceEnterpriseSearch(() => {
                  searchOrgNumber(query);
                });
              }}
              onChange={onChangeOrgNumber}
            />
            {!disableOrgNumber() && (
              <span className={styles.hint}>
                <FormattedMessage
                  key={translationKeys.invoicing_details_org_number_search_hint}
                  id={translationKeys.invoicing_details_org_number_search_hint}
                  values={{
                    email: (
                      <a
                        href="mailto: oversettelse@salita.no"
                        onClick={() => sendGAEvent(GAEventType.ContactEmailClicked)}
                      >
                        {'oversettelse@salita.no'}
                      </a>
                    ),
                  }}
                />
              </span>
            )}
            <div className={styles.blacklistErrorBanner}>
              {apiErrors && <Banner variant="error" text={apiErrors} />}
            </div>
          </div>
        )}
        {customerType == LoggedOutCustomerType.New && (
          <div data-testid="new-customer-department-dropdown">
            <SearchDropdown
              selected={selectedEnterpriseDepartment?.key ? [selectedEnterpriseDepartment] : []}
              options={enterpriseDepartments}
              placeholder={
                !selectedOrgNumber?.id
                  ? intl.formatMessage({
                      id: translationKeys.invoicing_details_department_placeholder_without_org_number,
                    })
                  : intl.formatMessage({
                      id: translationKeys.invoicing_details_enterprise_department_placeholder_new_customer,
                    })
              }
              label={intl.formatMessage({
                id: translationKeys.invoicing_details_enterprise_department_label,
              })}
              size="large"
              required
              errorText={
                showErrors && !selectedEnterpriseDepartment
                  ? intl.formatMessage({
                      id: translationKeys.invoicing_details_enterprise_department_required,
                    })
                  : undefined
              }
              onChange={(departments) => {
                if (departments && departments.length > 0) {
                  setSelectedEnterpriseDepartment(departments[0]);
                } else {
                  setSelectedEnterpriseDepartment(undefined);
                }
              }}
            />
          </div>
        )}
        {customerType == LoggedOutCustomerType.New && showNewDepartment() && (
          <TextField
            data-testid="new-department-text-field"
            placeholder={intl.formatMessage({
              id: translationKeys.invoicing_details_new_department_placeholder,
            })}
            type="text"
            label={intl.formatMessage({
              id: translationKeys.invoicing_details_new_department_label,
            })}
            size="large"
            name="departmentName"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.departmentName}
            errorText={formik.touched.departmentName ? formik.errors.departmentName : undefined}
          />
        )}
        <div className={styles.referenceInputs}>
          <div>
            <TextField
              data-testid="buyer-ref-text-field"
              placeholder={intl.formatMessage({
                id: translationKeys.invoicing_details_buyer_reference_placeholder,
              })}
              type="text"
              label={intl.formatMessage({
                id: translationKeys.invoicing_details_buyer_reference_label,
              })}
              size="large"
              name="buyerReference"
              onChange={(e) => {
                formik.handleChange(e);
                validateBuyerReference(e.target.value);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.buyerReference}
            />
            {formik.values.buyerReference && !isBookingReferenceValid && (
              <div className={styles.container}>
                <FontAwesomeIcon icon={faCircleExclamation} className={styles.icon} />
                <span className={styles.warning}>
                  {bookingReference?.bookingReferenceRegexHint ? (
                    bookingReference?.bookingReferenceRegexHint
                  ) : (
                    <FormattedMessage
                      id={translationKeys.invoicing_details_default_references_regex_hint}
                    />
                  )}
                </span>
              </div>
            )}
          </div>

          <div>
            <TextField
              data-testid="order-ref-text-field"
              placeholder={intl.formatMessage({
                id: translationKeys.invoicing_details_order_reference_placeholder,
              })}
              type="text"
              label={intl.formatMessage({
                id: translationKeys.invoicing_details_order_reference_label,
              })}
              size="large"
              name="orderReference"
              onChange={(e) => {
                formik.handleChange(e);
                validateOrderReference(e.target.value);
              }}
              onBlur={formik.handleBlur}
              value={formik.values.orderReference}
            />
            {formik.values.orderReference && !isOrderReferenceValid && (
              <div className={styles.container}>
                <FontAwesomeIcon icon={faCircleExclamation} className={styles.icon} />
                <span className={styles.warning}>
                  {bookingReference?.paymentBookingReferenceRegexHint ? (
                    bookingReference?.paymentBookingReferenceRegexHint
                  ) : (
                    <FormattedMessage
                      id={translationKeys.invoicing_details_default_references_regex_hint}
                    />
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.estimateContainer}>
          <InvoiceSummary
            data-testid="invoice-summary"
            estimate={orderEstimate?.amount || '0'}
            invoiceHeader={getInvoiceHeaderType()}
          />
        </div>
        <div>
          <Button
            data-testid="submit-button"
            type="submit"
            className={styles.nextButton}
            size="large"
            variant="primary"
            fullWidth
            isLoading={updateFinanceInfoRequestLoading}
          >
            {orderEstimate?.visible_for_customer ? (
              <FormattedMessage id={translationKeys.invoicing_details_place_order_button} />
            ) : (
              <FormattedMessage id={translationKeys.booking_estimate_request_submit_button} />
            )}
          </Button>
          <span className={styles.orderNote}>
            <FormattedMessage id={translationKeys.logged_out_booking_prices_note} />
          </span>
        </div>
      </Form>
    </div>
  );
};

export default BookingEstimate;
